import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Secteur from "../components/Secteur"
import classnames from "classnames"
import { Tree, Lever, allCategoriesType, allLeverType } from "../utils/model"
import { faSort } from "@fortawesome/free-solid-svg-icons"
import { useCategoryTree } from "../utils/custom-hooks"

type IndexPageProps = {
  data: {
    categories: allCategoriesType
    levers: allLeverType
  }
}

function IndexPage(props: IndexPageProps) {
  useEffect(() => {
    document.body.className = "page-home"
  }, [])

  const categories = useCategoryTree()
  const levers = props.data?.levers.nodes
    ? props.data?.levers.nodes
        .map(l => {
          let pictoURL = null
          if (l.data.LeverPictoURL) {
            pictoURL = l.data.LeverPictoURL
          }

          return new Lever(
            l.id,
            l.data.Name,
            l.data.Label?.childMarkdownRemark.html,
            pictoURL,
            l.data.Featured,
            l.data.Prio
          )
        })
        .sort((a, b) => {
          const nameA = a.prio
          const nameB = b.prio
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }

          return 0
        })
    : []
  return (
    <Layout contentClassName="container">
      <SEO
        title="SHIFT YOUR JOB - Concilier Emploi & Climat"
        description="Où travailler pour contribuer à la transition carbone ? Explorez les opportunités dans les secteurs contribuant à la transition et engagez-vous professionnellement pour le développement durable"
        gtmPageType="homepage"
        gtmPageName="homepage"
      />
      <div className="mx-2 pt-6 md:mx-auto md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl pb-16">
        <h1 className="font-sans text-7xl leading-none md:text-8xl md:whitespace-no-wrap xl:text-9xl font-black text-center outlined-title">
          Où travailler
        </h1>
        <h2 className="text-5xl text-primary font-black font-sans -mt-8 md:-mt-8 lg:-mt-12 mb-6 text-center leading-none">
          pour contribuer
          <br />
          à la transition carbone et avoir
          <br />
          un impact sur le climat?
        </h2>
        <p className="font-serif text-gray-700 mb-8 text-center text-lg max-w-2xl mx-auto">
          Explorez les opportunités dans les secteurs contribuant directement
          ou&nbsp;indirectement à la transition
        </p>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {categories.root.children.map(cat => (
            <Secteur key={cat.id} category={cat} />
          ))}
          {categories.root.children.length % 2 === 1 && (
            <div
              className="px-4 py-6 mt-4"
              style={{ width: "150px", height: "175px" }}
            >
              &nbsp;
            </div>
          )}
        </div>
      </div>
      <div className="relative mb-8 z-10 ">
        <div
          className="absolute bg-secondary top-0 left-1/2 "
          style={{ width: "4px", height: "64px" }}
        ></div>
      </div>
      <div className="bg-primary text-white relative px-10 py-12 text-center">
        <h2 className="text-2xl md:text-4xl font-sans font-bold mb-4 text-center">
          Notre ambition
        </h2>
        <p className="max-w-lg mx-auto">
          Vous guider pour aligner votre engagement professionnel avec l’enjeu
          de limiter le dérèglement climatique
        </p>
      </div>
      <div className="relative mb-8 z-10 -mt-6">
        <div
          className="absolute bg-secondary top-0 left-1/2 "
          style={{ width: "4px", height: "64px" }}
        ></div>
      </div>
      <div className="border-secondary border-4 relative p-10 mt-16 mb-8 text-center md:mx-auto md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl">
        <div className="my-4">
          <h2 className="text-primary text-2xl md:text-4xl font-black mb-4 text-center">
            Les critères de sélection
          </h2>
          <p className="text-gray-700 max-w-xl mx-auto text-left">
            Pour chaque secteur nos critères de sélection ont été élaborés à
            partir de scénario de référence inspirés des rapports du Shift
            Project, du Drawdown Project ou de la Taxonomie Européenne - EU TEG
            Taxonomy.
            <br />
            <br />
            Chaque entrée active par ailleurs au moins un des 6 leviers d’impact
            :
          </p>
          <ul className="my-4 text-left text-primary md:flex md:flex-wrap max-w-xl mx-auto">
            {levers.map(l => (
              // if (l.id !== 2 && l.id != 3) {
              <li
                key={l.id}
                className={classnames(
                  "font-sans flex items-center text-sm font-bold my-3 md:w-1/2",
                  { hidden: !l.featured }
                )}
              >
                <span className="w-10 mr-2 text-center">
                  <img className="mx-auto" src={l.pictoURL} />
                </span>
                <span>{l.name}</span>
              </li>
              // }
            ))}
          </ul>
        </div>
        <div className="mx-auto pt-4">
          <Link
            to={"/philosophie"}
            className="font-sans font-bold text-sm bg-secondary text-primary text-center rounded px-6 py-4 transition duration-300 ease transition-colors hover:bg-primary hover:text-secondary"
          >
            Tout sur les critères et leviers
          </Link>
        </div>
      </div>

      <div className="relative bg-primary text-white pt-8 pl-4 pb-4 lg:p-10 mt-16 mb-8 text-left">
        <h2 className="font-sans outlined-subtitle text-5xl font-black leading-none lg:text-6xl xl:text-7xl lg:p-20">
          Un projet
          <br />
          collaboratif
        </h2>
        <h3 className="text-2xl text-white font-black -mt-16 mb-20 lg:mb-40 lg:-mt-40 xl:-mt-48 lg:ml-20 lg:text-3xl">
          Shiftyourjob.org
        </h3>

        <div
          className="lg:absolute font-serif text-lg mb-2 lg:mb-0 bg-shift p-10 lg:w-1/2 lg:p-20"
          style={{ bottom: "0", right: "0" }}
        >
          <p className="mb-4">
            Ce projet est porté par les Shifters, les bénévoles du{" "}
            <a
              href="https://theshiftproject.org"
              target="_blank"
              className="underline"
            >
              Shift Project
            </a>
            , think tank qui œuvre en faveur d’une économie décarbonée.
          </p>
          <p className="mb-10">Vous pouvez vous aussi y contribuer ! </p>
          <Link
            to="/contribuer"
            className="font-sans font-bold text-sm bg-transparent rounded border-2 border-secondary text-secondary text-center px-6 py-4 transition duration-300 ease transition-colors hover:bg-secondary hover:text-primary "
          >
            Contribuer
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query HomeQuery {
    levers: allAirtable(filter: { table: { eq: "Lever" } }) {
      nodes {
        id
        data {
          Name
          Label {
            childMarkdownRemark {
              html
            }
          }
          Featured
          LeverPictoURL
          Picto {
            localFiles {
              url
            }
          }
          Prio
        }
      }
    }
  }
`

export default IndexPage
